.popBOX.BlockPopup.qrproduct-pop {
  width: 645px !important;
  height: 75vh;
  overflow: auto;
  backdrop-filter: none !important;
}

p.product-code-head {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

p.pro-id {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.qr-product-scan {
  text-align: center;
}

.qr-product-scan img {
  width: 40%;
}

.product-img-bag {
  display: flex;
  gap: 35px;
  margin-top: 30px;
}

p.product-client-id {
  font-size: 20px;
  font-weight: 700;
}

p.product-client-id span {
  color: #0070f0;
  font-size: 20px;
  font-weight: 700;
}

.category-sctn {
  text-align: center;
  border-radius: 15px;
  border: 2px solid #45c459;
  background: #fff;
  max-width: 344px;
  margin: 50px auto;
  padding: 33px 23px 47px 27px;
}

.approve-button {
  border-radius: 2.921px;
  border: 1.461px solid #00ff29;
  background: linear-gradient(134deg, #1ee13d -26.79%, #8b8b8b 230.75%);
  color: #000 !important;
  padding: 6px 25px;
}

a.url-link {
  text-decoration: none;
  color: #0070f0;
}

.action-part {
  text-align: center;
}

p.action-head {
  font-size: 20px;
  font-weight: 700;
}

p.action-part-cnt {
  font-size: 15px;
  font-weight: 400;
}

p.action-info {
  color: #e01818;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  max-width: 414px;
  margin: 0 auto;
}

.certificate-btn button {
  border-radius: 4.519px;
  border: 2.259px solid #00ff47;
  background: linear-gradient(134deg, #00ff57 -26.79%, #8b8b8b 230.75%);
  width: 100%;
  color: #fff !important;
}

.certificate-btn {
  text-align: center;
  margin-bottom: 5px;
}

.generate-btns {
  margin-top: 35px;
}

.certificate-btn.qr-code button {
  border-radius: 4.519px;
  border: 2.259px solid rgba(235, 255, 0, 0.36);
  background: linear-gradient(
    134deg,
    #ffd600 -26.79%,
    #8b8b8b 230.75%
  ) !important;
}

.certificate-btn.print-btns button {
  border-radius: 4.519px;
  border: 2.259px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
}

p.email-send {
  font-size: 15px;
  font-weight: 700;
  max-width: 162px;
}

.email-certificate {
  display: flex;
  justify-content: space-around;
}

.email-input-box {
  margin-top: 35px;
  border-radius: 20px;
  border: 1px solid #0070f0;
  background: #fff;
  padding: 30px;
}

.input-txt-box {
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  border: none !important;
}

p.default-para {
  font-size: 13px;
}

.default-acct {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.successfull-certificate {
  text-align: center;
  margin-top: 40px;
}

.successfull-certificate {
  text-align: center;
  margin-top: 40px;
  border-radius: 20px;
  border: 1px solid #0070f0;
  background: #fff;
  padding: 30px;
}

p.success-msg {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.red-tick {
  color: #ff0000;
  font-size: 25px !important;
}

.green-tick {
  font-size: 25px !important;
  color: #58a626;
}

.popBOX.BlockPopup.rejectpop {
  border-radius: 20px;
  background: rgba(38, 38, 38, 0.4) !important;
  backdrop-filter: none !important;
  height: 77%;
  overflow: auto;
}

p.reject-head {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

p.reason-reject {
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.check-list ul {
  list-style-type: none;
  color: #fff;
  font-size: 20px;
  padding-left: 74px;
}

.box-border path {
  color: #0070f0;
}

.check-list {
  margin-top: 50px;
}

.check-list ul li {
  margin-bottom: 15px;
}

button.decline {
  border-radius: 4.519px;
  border: 2.259px solid #ffc700;
  background: linear-gradient(134deg, #f90 -26.79%, #8b8b8b 230.75%);
  color: #fff;
  padding: 8px 27px;
}

.decline-cancel-btn {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
}

button.cancel {
  border-radius: 6.2px;
  border: 3.1px solid #ff1b00;
  background: linear-gradient(134deg, #e52a13 -26.79%, #c53a2a 230.75%);
  color: #fff;
  padding: 8px 27px;
}

.text-box {
  text-align: center;
}

.box-size {
  width: 600px;
  padding: 30px !important;
  border-radius: 10px;
  background: #fff;
  box-shadow: 5.26495px 5.26495px 12.28487px 0px rgba(156, 156, 156, 0.48) inset,
    -5.26495px -5.26495px 12.28487px 0px #fff inset;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

button.approve {
  border-radius: 4.519px;
  border: 2.259px solid #00ff29;
  background: linear-gradient(134deg, #1ee13d -26.79%, #4ebc60 230.75%);
  padding: 8px 18px;
  color: #fff;
}

.popBOX.BlockPopup.rejectpop.approve {
  height: 40%;
  padding: 80px !important;
}

p.reason-reject.approval-txt {
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 30px;
}

p.scan-blockchain-para {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.address-detail p.scan-blockchain-para {
  text-align: left !important;
}

.address-detail p.scan-blockchain-para span {
  font-size: 26px !important;
  font-weight: 400 !important;
  word-wrap: break-word;
}

.popBOX.BlockPopup.scanpopup {
  backdrop-filter: none !important;
}

p.view-over {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 9px;
}

.head-over {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid;
}

.overview-box-outer {
  border-radius: 15px;
  background: #d9d9d9;
  padding: 10px 41px 15px 46px;
  width: 78%;
  height: 100%;
}

.value-overview span {
  color: #0070f0;
}

.trans-tab-table {
  margin-top: 50px;
  background: #d9d9d9;
}

.name-part.Mui-selected {
  color: #000 !important;
  font-weight: 500 !important;
  border-radius: 15px;
  border: 1px solid #0070f0;
}

.name-part {
  color: #000 !important;
  font-weight: 500 !important;
  border-radius: 15px;
}

.body-bg {
  background: transparent !important;
  /* border-bottom: 2px solid #0070F0 !important; */
  /* edited by Sankar */
  font-size: 15px !important;
  font-weight: 600 !important;
  /* padding: 10px 0 !important; */
}

.trans-tab-table .css-19kzrtu {
  padding: 0 !important;
}

.tab-head-part {
  padding: 30px;
}

.head-bg {
  background: rgba(174, 192, 236, 0.8) !important;
  color: #000 !important;
  text-align: center !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  padding: 15px 30px !important;
}

.info-product-scan img {
  width: 45%;
}

.info-product-scan {
  text-align: center;
}

p.lv-bag {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

p.lv-made {
  color: #0070f0;
  font-size: 21px;
}

/* .special-bag {
    margin-top: 100px;
} */

.productinfo-img-bag {
  display: flex;
  gap: 35px;
  margin-top: 30px;
  text-align: center;
  justify-content: center;
}

.popBOX.BlockPopup.infopopup {
  width: 965px !important;
  backdrop-filter: none !important;
}

.category-sctn.info-category {
  border-radius: 15px;
  border: 2px solid #da952d;
  background: #fff;
}

.category-sctn.info-category p.product-client-id span {
  color: #0070f0;
  font-size: 17px;
  font-weight: 700;
}

.pending-appr-button {
  border-radius: 2.921px;
  border: 1.461px solid #ffc700;
  background: linear-gradient(134deg, #f90 -26.79%, #8b8b8b 230.75%);
  color: #000 !important;
  padding: 12px;
}

.approve-tick {
  border-radius: 2.921px;
  border: 1.461px solid #47c15b;
  background: linear-gradient(134deg, #40c755 -26.79%, #579e62 230.75%);
  color: #000 !important;
  padding: 12px;
}

.pend-approved-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wrong-tick {
  border-radius: 2.921px;
  border: 1.461px solid #f00;
  background: linear-gradient(134deg, #f00 -26.79%, #8b8b8b 230.75%);
  color: #000 !important;
  padding: 12px;
}

.buttons-approve {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ean-info {
  display: flex;
  gap: 50px;
  overflow: auto;
}

p.product-info-title {
  font-weight: 700;
  font-size: 19px;
}

.warranty-extension {
  display: flex;
  justify-content: space-around;
  border-radius: 15px;
  background: #d9d9d9;
}

p.blue-msg {
  color: #4e8deb;
  font-weight: 500;
  font-size: 18px;
}

.edit-info {
  border-radius: 4.519px;
  border: 2.259px solid #ffc700;
  background: linear-gradient(134deg, #f90 -26.79%, #8b8b8b 230.75%);
  color: #fff !important;
  padding: 10px 24px !important;
}

.edit-doenlod-info {
  margin-top: 50px;
}

.down-info {
  border-radius: 6.2px;
  border: 3.1px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
  color: #fff !important;
  padding: 10px 24px !important;
}

.edit-doenlod-info {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

/* .cristin-para {
  max-width: 500px !important;
} */

.popBOX.BlockPopup.requpdate .reject-head,
.popBOX.BlockPopup.requpdate p.reason-reject,
.popBOX.BlockPopup.requpdate .check-list ul {
  color: #000 !important;
}

.popBOX.BlockPopup.requpdate {
  backdrop-filter: none !important;
}

.req-up {
  background: rgba(235, 255, 0, 1) !important;
  border-radius: 6.2px;
  border: 3.1px solid rgba(235, 255, 0, 1);
  color: #000 !important;
}

.popBOX.BlockPopup.requpdate.block .check-list {
  display: flex;
  justify-content: center;
}

.popBOX.BlockPopup {
  height: 78vh;
  overflow: auto;
}

p.red-alert {
  color: #f00;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}

.popBOX.BlockPopup.blockproduct {
  height: 40%;
  padding: 80px;
}

p.block-head {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

Table.tableScroll {
  overflow-x: auto;
  display: block;
}

.tab-head-part .MuiTabs-scroller {
  overflow: auto !important;
}

.reactive-size-check {
  max-width: 186px;
  margin: 0 auto;
}

.size-row {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.other-size {
  text-align: center;
  margin-top: 25px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
}

.req-up.print {
  padding: 7px 25px;
}

.eye-direct {
  display: flex;
  gap: 4px;
  align-items: center;
}

@media (max-width: 767.98px) {
  .popBOX.BlockPopup.qrproduct-pop {
    width: 286px !important;
  }

  .product-img-bag {
    display: block;
    text-align: center;
  }

  p.pro-id {
    font-size: 22px;
  }

  .email-certificate {
    display: block;
  }

  input#outlined-password-input {
    width: auto !important;
  }

  .box-size {
    width: auto;
  }

  .check-list ul {
    padding-left: 0;
  }

  .popBOX.BlockPopup.infopopup {
    width: 300px !important;
  }

  .productinfo-img-bag {
    display: block;
  }

  .edit-doenlod-info {
    display: block;
    text-align: center;
  }

  .edit-doenlod-info button {
    margin-bottom: 10px;
  }

  .overview-box-outer {
    width: auto;
  }

  .head-over {
    display: block;
  }

  .trans-tab-table .css-19kzrtu {
    padding: 10px !important;
  }

  .warranty-extension {
    display: block;
    text-align: center;
    padding: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .popBOX.BlockPopup.infopopup {
    width: 610px !important;
  }

  .warranty-extension {
    display: block;
    text-align: center;
    padding: 10px;
  }
}
