.logo-outer-part {
  display: flex;
  align-items: center;
}

/* .brandname {
    height: 100px;
    width: 200px;
    border-radius: 5px;
} */
.brandname {
  height: 90px;
  width: auto;
  border-radius: 5px;
  background: #ffffff;
  padding: 4px;
}
.cancelbtn {
  margin-top: 20px !important;
}
.opendiv {
  width: 100%;
}

.brandname img {
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.logo-outer-part p {
  margin-bottom: 0px;
  font-size: 15px;
  text-align: left;
  color: #fff;
  padding-left: 20px;
  margin-top: 0px;
}

.search-nmwp {
  display: flex;
  height: 100%;
  align-items: center;
}

.search-nmwp > div {
  width: 50%;
}

.nmwp {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.nmwp img {
  width: 25px;
}

/* .dashboard-page {
    background: url(../../img/background.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} */

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-section p {
  margin: 0px;
  padding: 5px 0;
  color: #000;
  font-size: 20px;
}

.togglebtn img {
  width: 95px;
  cursor: pointer;
}

.togglebtn {
  position: fixed !important;
  top: auto !important;
  /* left: -5px; */
  z-index: 9;
}

.categories-menu a {
  display: flex;
  gap: 10px;
  text-decoration: none;
  color: #000 !important;
  font-size: 16px;
  align-items: flex-start;
  padding: 10px;
  border-radius: 6.2px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 3.1px 0px 0px #fff inset,
    3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16);
  margin-top: 20px;
}

.categories-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 5px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 2.22222px 0px 0px #fff inset,
    2.22222px 8.88889px 26.66667px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  z-index: 9999;
  top: 120px;
  left: 13px;
  transition: all 0.3s ease-in-out;
  transform: translateX(-110%);
  height: 78vh;
  overflow-y: scroll;
}

.togglebtn-close {
  text-align: left;
  cursor: pointer;
}

.categories-menu-inner {
  background: rgba(255, 255, 255, 0.76);
  stroke-width: 0.986px;
  stroke: rgba(203, 239, 255, 0.16);
  backdrop-filter: blur(98.61111450195312px);
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
}

.categories-menu-inner :first-child {
  margin-top: 0px;
}

div#openmenucls {
  transform: translateX(0px);
}

.profileicomstaticbig img {
  width: 52px;
  height: 52px;
}

.profile-detail-block {
  background: rgba(255, 255, 255);
  width: 240px;
  border-radius: 20px;
}

.profile-detail-block p {
  margin: 0px;
  font-weight: 800;
}

.profile-menu {
  text-align: left;
  padding: 10px;
}

.profile-menu a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profileicomstaticbig {
  padding: 20px 20px 10px 20px;
}

.profile {
  position: relative;
}

.profile-detail-block-new ul.MuiList-root p {
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.profileicomstaticbig {
  text-align: center;
}

.profile-detail-block-new ul.MuiList-root {
  width: 240px;
  padding-bottom: 0px;
}

.profile-detail-block-new ul.MuiList-root span {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.profile .profile-img img,
.profileicomstaticbig img {
  /* width: 30px;
    height: 30px; */
  clip-path: circle(50% at 50% 50%);
}

.faq-logout {
  background: rgba(174, 192, 236, 0.8);
  backdrop-filter: blur(39.04999923706055px);
  display: flex;
  justify-content: space-around;
}

.faq-logout a {
  padding: 15px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  gap: 5px;
  font-size: 14px;
}

.notify-message-action {
  display: flex;
  justify-content: end;
}

.notify-message {
  /* display: flex; */
  align-items: center;
}

.notify-message-detail p {
  margin: 5px 0px;
}

.notify-message-detail {
  padding-left: 10px;
}

.notify-message-img img {
  clip-path: circle(50% at 50% 50%);
}

.notify-message-img img {
  width: 25px;
  height: 25px;
}

.notify-message {
  padding: 15px;
  margin: 5px 2px;
  border-radius: 5.289px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 2.64452px 0px 0px #fff inset,
    2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16);
}

.notifiction-detail-block-new ul.MuiList-root {
  padding: 0px !important;
  height: 268px;
  overflow-x: hidden;
}

.notifiction-detail-block-new ul.MuiList-root .MuiStack-root.view-all-caption {
  padding: 10px;
  justify-content: space-between !important;
  align-items: center;
  background: rgba(174, 192, 236, 0.8);
  backdrop-filter: blur(39.04999923706055px);
}

.view-all-caption button {
  text-transform: none;
}

/* .whole-notify-inbox-outer .notify-message {
    width: 100%;
} */

.tab-content-whole-notify-inbox-outer {
  /* width: 70%; */
  margin: auto;
}

.whole-notify-inbox-outer .MuiTabs-root.MuiTabs-vertical {
  padding-top: 29px;
  border: none;
  width: auto;
}

.whole-notify-inbox-outer .MuiTabs-root.MuiTabs-vertical button {
  text-transform: capitalize;
  margin: 5px 0;
}

.whole-notify-inbox-outer .MuiTabs-root.MuiTabs-vertical button.inbox-tab {
  border-radius: 5.289px;
  /* border: 2.645px solid #3EFF81; */
  background: linear-gradient(
    134deg,
    #ffcc8f -26.79%,
    #d8b68e 59.58%,
    #8b8b8b 230.75%
  );
  /* box-shadow: 0px 2.64452px 0px 0px #FFF inset, 2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16); */
  padding: 0pc !important;
  color: #fff;
  font-size: 16px;
}

.whole-notify-inbox-outer
  .MuiTabs-root.MuiTabs-vertical
  span.MuiTabs-indicator {
  display: none;
}

.whole-notify-inbox-outer
  .MuiTabs-root.MuiTabs-vertical
  button.Create-Incitant-tab {
  border-radius: 5.289px;
  border: 2.645px solid rgba(250, 141, 12, 0.4);
  background: linear-gradient(
    134deg,
    #ffcc8f -26.79%,
    #d8b68e 59.58%,
    #8b8b8b 230.75%
  );
  /* box-shadow: 0px 2.64452px 0px 0px #FFF inset, 2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16); */
  color: #fff;
  font-size: 16px;
}

.whole-notify-inbox-outer.MuiModal-root.css-79ws1d-MuiModal-root .MuiBox-root {
  padding: 20px;
  position: relative;
  width: 90% !important;
}

.close-change-passwrd-pop-up.close-inbox-pop-up {
  position: absolute;
  right: 9px;
  top: 9px;
}

.notify-message.notify-message-all-view {
  justify-content: space-between;
  border-radius: 20px;
  padding: 10px 20px;
}

.message-count {
  display: flex;
  gap: 10px;
}

.message-count-outer {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.notify-message.notify-message-all-view .notify-message-detail strong span {
  color: #1976d2;
}

.notify-message.notify-message-all-view .notify-message-detail strong {
  display: inline-block;
  width: 100%;
}

.whole-inbox-action-btns {
  display: flex;
  padding: 22px 50px;
  border-radius: 0px 0px 12.796px 12.796px;
  border: 0.757px solid rgba(203, 239, 255, 0.16);
  background: rgba(174, 192, 236, 0.8);
  backdrop-filter: blur(33.3123893737793px);
  gap: 30px;
}

.whole-notify-inbox {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 2.64452px 0px 0px #fff inset,
    2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16);
}

.notify-message.notify-message-all-view button {
  text-transform: none;
  height: 27px;
}

.whole-notify-inbox .notify-message.notify-message-all-view {
  margin-top: 0px;
  margin-bottom: 20px;
}

.whole-notify-inbox-outer .MuiTabs-root.MuiTabs-vertical {
  background: rgba(200, 242, 255, 0.76);
  padding: 20px;
}

.text-field-issue {
  margin-bottom: 20px;
}

.text-field-issue label {
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 600;
}

.text-field-issue .MuiInputBase-root:hover {
  border: none;
}

a.Add-More-Details {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  text-align: right;
  color: #1976d2;
}

.Create-Issue-btn-submit {
  text-align: center;
}

.Create-Issue-btn-submit button {
  text-transform: none;
}

.text-field-issue fieldset {
  border-radius: 6.458px;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  height: 54px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
}

.whole-notify-inbox-outer th {
  background: rgba(174, 192, 236, 0.8);
  backdrop-filter: blur(33.3123893737793px);
}

.whole-notify-inbox-outer table {
  margin-top: 30px;
}

.faq-head h4 {
  margin: 0px;
  padding: 20px;
  text-align: center;
  background: rgba(174, 192, 236, 0.8);
}

.faq-head {
  margin-top: 30px;
}

label.category-label {
  color: #000;
  font-weight: 700;
  font-size: 14px;
}

label.category-label span {
  color: #003ed0;
  text-decoration: underline;
  display: inline-block;
}

.faq-body div.MuiPaper-root {
  /* border-radius: 5.289px;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.64452px 0px 0px #FFF inset, 2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16); */
  border: none !important;
  /* margin-top: 20px !important; */
  background: none !important;
}
.faq-body .headTitle {
  text-align: left !important;
  margin-left: 10px;
  color: #000;
  font-size: 20px !important;
  margin-bottom: 10px !important;
}
.faq-qus-title .MuiAccordionSummary-content {
  margin: 0;
  margin-left: 8px;
}
.faq-body .faq-qus-title {
  padding: 10px !important;
  background: none !important;
}
.faq-ans-para p {
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #444;
}
.faq-qus-title p {
  line-height: 1.3;
  font-weight: 600;
}

.faq-ans-para p.faq-link a {
  border-bottom: 1px solid #0065ff;
}
.faq-ans-para {
  padding: 0 30px 30px 30px !important;
}
.menu.faq-menus a.togglebtn-close img {
  width: 40px;
}
.menu.faq-menus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.faq-head-body {
  padding: 0 50px;
}
.popBOX.message-popup.ppp {
  height: 60% !important;
}
.faq-body {
  margin-top: 40px;
}

.language-style{
  vertical-align: super !important;
}

.flags {
  max-width: 25px !important;
}

.nmwp .icon svg {
  font-size: 26px !important;
  color: #fff;
}

.connect-wallet-btn img {
  width: 30px;
  margin-right: 10px;
}

.connect-wallet-btn {
  color: #000 !important;
  background: #e8e8e8 !important;
  display: flex !important;
  align-items: center !important;
  padding: 12px !important;
  width: 100% !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

.connect-wallet-btn div {
  display: flex !important;
  align-items: center !important;
}

.connect-wallet-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connect-wallet-head svg {
  font-size: 27px !important;
}

.connect-wallet-head {
  font-size: 27px !important;
  margin-bottom: 20px;
}

.faq-logout.profile-menu {
  display: block;
  background: transparent;
}

.faq-logout.profile-menu a {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .total-pdts-div {
    flex-direction: column;
  }

  .logo-outer-part {
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }

  .brandname {
    margin-bottom: 30px;
    display: none;
  }

  .search-nmwp {
    flex-direction: column;
    gap: 11px;
    align-items: center;
    justify-content: center;
  }

  .search-nmwp input {
    width: 100%;
  }

  .search-nmwp > div {
    width: 100%;
  }

  .nmwp {
    justify-content: flex-end;
  }

  div#dashboargraph {
    padding: 15px !important;
  }

  .dashboargraph-inner-mid {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .total-pdts {
    margin-top: 35px;
  }

  .dashboargraph-inner-mid {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .total-pdts {
    margin-top: 0px !important;
  }

  .QR-codes {
    flex-direction: column;
  }

  .total-qr-codes-activated {
    width: 100% !important;
  }

  p.font-size-cmn {
    font-size: 16px;
    font-weight: 700;
  }

  .dashboargraph-part {
    padding: 15px !important;
    margin: 0px !important;
  }

  .footer-section p {
    font-size: 16px;
  }

  .togglebtn {
    top: 0;
    position: absolute;
  }

  .categories-menu {
    top: 150px !important;
  }

  .total-pdts {
    padding: 20px;
  }

  .whole-inbox-action-btns {
    flex-direction: column;
    padding: 15px 20px;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .categories-menu {
    top: 250px;
  }
}

.message-popup-tab {
  flex-direction: row !important;
  justify-content: center !important;
  gap: 20px;
}

.watch-text {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 18px;
  font-weight: 600;
}

.watch-text img {
  width: 40px;
}

.demo-img img {
  width: 60px !important;
  display: flex;
  flex-direction: column;
}

.user-side-display {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.logout {
  width: 22px;
  margin-right: 10px;
}

.contact-us {
  color: #000;
  text-align: center;
  text-transform: capitalize;
}

.categories-menu2 a {
  display: flex;
  gap: 10px;
  text-decoration: none;
  color: #000 !important;
  /* font-size: 18px;
    font-weight: 600; */
  justify-content: center !important;
  padding: 10px;
  border-radius: 6.2px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 3.1px 0px 0px #fff inset,
    3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16);
  margin-top: 20px;
}

.categories-menu2 {
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 10px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 2.22222px 0px 0px #fff inset,
    2.22222px 8.88889px 26.66667px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  z-index: 9999;
  top: 108px;
  left: 13px;
  transition: all 0.3s ease-in-out;
  transform: translateX(-110%);
}

.profile-img img {
  height: 30px;
  width: 31px;
}

.whole-notify-inbox-outer.MuiModal-root.css-79ws1d-MuiModal-root .MuiBox-root {
  width: 90%;
}

@media (max-width: 575px) {
  .faq-head-body {
    padding: 0px !important;
  }

  .logo img {
    width: 150px;
  }

  .notify-message.notify-message-all-view button {
    min-width: 70px;
    padding: 5px 10px;
  }

  .nmwp {
    justify-content: center !important;
  }
}

div#message-tab .MuiTabs-flexContainer {
  flex-direction: row !important;
  justify-content: center !important;
}


.language {
  color : #fff;
  cursor : pointer; 
  vertical-align : center !important;
  font-size : 1.5em;
}