.post-sale-admin-block {
    border-radius: 10px;
    border: 1.5px solid #0065FF;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.64452px 0px 0px #FFF inset, 2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16);
    width: 20% !important;
    overflow: hidden;
}

.post-sale-admin-block>div {
    padding: 5px;
}

.loaders-loads {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.loaders-loads img {
    width: 250px;
}

.post-sale-admin-block div:first-child {
    background: rgba(174, 192, 236, 0.80);
    backdrop-filter: blur(33.3123893737793px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-sale-admin-block div:first-child label {
    font-size: 14px;
    color: #000;
}

.post-sale-admin-block span img {
    height: 35px;
}

.post-sale-admin-block div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    /* / height: 100%; / */
}

.post-sale-admin-block span {
    color: #000;
}

.many-block-with-label-span-post-sale-admin {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}

@media all and (max-width: 991.98px) {

    /*Edited by sankar*/
    .many-block-with-label-span-post-sale-admin {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }
}

.post-sale-admin-block {
    border-radius: 10px;
    border: 1.5px solid #0065FF;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.64452px 0px 0px #FFF inset, 2.64452px 10.57807px 31.73421px 0px rgba(0, 0, 0, 0.16);
    width: 13%;
    overflow: hidden;
}

.post-sale-admin-block>div {
    padding: 5px;
}

.post-sale-admin-block div:first-child {
    background: rgba(174, 192, 236, 0.80);
    backdrop-filter: blur(33.3123893737793px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-sale-admin-block div:first-child label {
    font-size: 14px;
    color: #000;
}

.post-sale-admin-block span img {
    height: 35px;
}

.post-sale-admin-block div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.post-sale-admin-block span {
    color: #000;
}

.action-ps-options img {
    width: 15px;
}

.color-orange-bg {
    background: #FFC989;
    backdrop-filter: blur(33.3123893737793px);
}

.action-ps-options {
    gap: 7px;
}

.color-green-bg {
    background: #00E55B;
    backdrop-filter: blur(33.3123893737793px);
}

.color-red-bg {
    background: #FF8989;
    backdrop-filter: blur(33.3123893737793px);
}

.post-sale-admin-block.post-sale-admin-block-status {
    width: 100%;
}

.post-sale-admin-block.post-sale-admin-block-status svg {
    font-size: 30px !important;
}

.color-green-color svg {
    color: #00e45b;
}

.color-red-color svg {
    color: #a00101;
}

.bg-color-new-block {
    border-radius: 16.667px;
    border: 0.986px solid rgba(203, 239, 255, 0.16);
    background: rgba(255, 255, 255, 0.76);
    backdrop-filter: blur(98.61111450195312px);
    margin: 15px 0;
    padding: 30px;
}

.bg-blue-padding-30px {
    background: rgba(0, 112, 240, 0.20);
    border-radius: 20px;
    padding: 5px 20px;
}

.popBOX.adminFilterPopup.AdminSettingPopup.AdminSettingPopup-postsale.smallpopup {
    width: 300px;
    cursor: pointer;
}

@media (max-width:767.98px) {

    .post-sale-admin-block {
        width: 100%;
    }

    .post-sale-admin-block div:first-child,
    .post-sale-admin-block div:nth-child(2) {
        height: auto;
    }

    .post-sale-admin-block.post-sale-admin-block-status {
        margin-top: 20px;
    }

    .popBOX.adminFilterPopup.AdminSettingPopup.AdminSettingPopup-postsale.smallpopup {
        width: 200px !important;
        height: 150px !important;
    }

}

@media (min-width:768px) and (max-width:991.98px) {

    .post-sale-admin-block {
        width: 48%;
    }

    .post-sale-admin-block div:first-child,
    .post-sale-admin-block div:nth-child(2) {
        height: auto;
    }

    .post-sale-admin-block.post-sale-admin-block-status {
        margin-top: 20px;
    }

}