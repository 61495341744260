.left-side-txt img {
  width: 220px;
}

.did-have-account {
  display: flex;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.did-have-account span,
a {
  font-size: 14px;
  /* color: #0065FF !important; */
}

.logo-side1 {
  margin-right: 0px !important;
  margin-top: -70px !important;
}

.logo-side1 p {
  color: #151515;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  max-width: 300px;
  margin: 0;
  padding-left: 55px;
  line-height: 1.5;
}

/* .right-content{
    margin-top: 70px !important;
  } */
/* .right-content::after {
    content: "";
    position: absolute;
    top: 100px;
    right: 140px;
    width: 200px;
    height: 188px;
    background-image: url(../../img/diamond-form-dec.png);
    background-repeat: no-repeat;
    background-size: cover;
  } */

.diamond-img {
  position: absolute;
  top: 0;
  right: 0;
}

.login-page1 {
  background-image: url(../../../img/Userloginnewbg.png);
  padding: 0px !important;
  position: relative;
  box-shadow: none !important;
  border-radius: 0px !important;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  height: 100vh;
  align-items: center;
  overflow: scroll !important;
  position: relative;
}

.form-input1 {
  position: relative;
  border-radius: 15px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 2px 0px 0px #fff inset, 2px 8px 24px 0px rgba(0, 0, 0, 0.16);
  padding: 40px 30px;
  height: auto;
  max-width: 430px;
  border-top: 6px solid rgba(174, 192, 236, 0.34);
  border-bottom: 6px solid rgba(174, 192, 236, 0.34);
  border-width: 70px;
  margin: 0 auto;
}

.right-content {
  position: relative;
  margin-top: 50px !important;
}

.form-input .login-txt h4 {
  margin: 0;
  color: #151515;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 7px;
}

.form-input .login-txt h1 {
  margin: 0;
  color: #151515;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
}

.cmn-input-cls .textField {
  margin: 0;
}

.login-contain {
  margin-top: -200px;
}

.form-input {
  position: relative;
}

.diamond-img img {
  width: 200px;
}

.diamond-img {
  position: absolute;
  top: -120px;
  right: -120px;
}

.cmn-input-cls .textField input::placeholder {
  color: #000 !important;
}

.cmn-input-cls .MuiInputBase-root {
  width: 400px;
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  height: 54px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
}

.cmn-input-cls {
  margin-bottom: 20px;
}

.cmn-input-cls .textField fieldset {
  /* border: none !important; */
  border-color: #9c9c9c00 !important;
  color: #151515 !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
}

.cmn-input-cls .textField::placeholder {
  color: #151515 !important;
}

.crtPassword {
  font-style: normal;
  font-weight: 400;
  margin-left: 3px;
  text-align: center !important;
}

.crtPassword a {
  text-decoration: none !important;
  font-size: 16px;
  color: #0054b8;
  cursor: pointer;
}

.form-input1 Button.submit {
  border-radius: 6.2px;
  border: 3.1px solid #0065ff !important;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%) !important;
  box-shadow: 0px 3.1px 0px 0px #fff inset,
    3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16) !important;
  color: #151515 !important;
  font-size: 14px !important;
  display: flex;
  margin: auto;
  text-transform: capitalize !important;
  margin-top: 15px;
}

Button.meta {
  color: #151515 !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 800;
  line-height: 18px !important;
  border-radius: 4px;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%);
  box-shadow: 0px 2px 0px 0px #fff inset, 2px 8px 24px 0px rgb(0 0 0 / 19%);
  margin: auto;
  display: flex;
  padding: 0px 11px;
}

.choice {
  display: flex;
  justify-content: center;
  margin: 23px auto 20px;
  color: #151515;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
}

/* .login-page.register {
    height: auto;
  } */

@media (max-width: 991.98px) {
  .left-side-txt {
    text-align: center;
  }

  .we-product {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    margin-bottom: 30px;
  }

  .we-product p {
    max-width: 250px;
    margin: 0 auto !important;
    margin-left: 50px;
  }

  .login-page1 {
    background-image: url(../../../img/Userloginnewbg.png);
    display: flex;
    align-items: flex-start;
  }

  .login-page1.register {
    display: block;
    align-items: flex-start;
  }

  .cmn-input-cls .MuiInputBase-root {
    width: 100%;
  }

  .form-input {
    width: 80%;
    /* padding-right: 0px !important; */
    margin: 0 auto;
  }

  .logo-side {
    padding-left: 0;
  }

  .logo-side p {
    max-width: 300px;
    margin: 0 auto;
    padding-left: 0;
    line-height: 1.5;
    text-align: center;
  }

  .diamond-img img {
    display: none;
  }

  .crtPassword a {
    font-size: 13px;
  }

  .form-input .login-txt h4 {
    font-size: 19px;
  }

  .form-input .login-txt h1 {
    font-size: 22px;
  }

  .cmn-input-cls .textField {
    margin-bottom: 0px !important;
    margin: 0;
    width: 100%;
  }

  .cmn-input-cls {
    margin-bottom: 20px;
  }

  .cmn-input-cls .textField fieldset {
    width: 93% !important;
  }

  .crtPassword {
    margin-left: 3px;
    margin-top: -7px !important;
  }

  Button.submit {
    font-size: 15px !important;
  }

  Button.meta {
    font-size: 13px !important;
  }

  .form-input1 {
    width: 80%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .cmn-input-cls .MuiInputBase-root {
    width: 400px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .crtPassword {
    font-size: 16px !important;
    margin-left: 5px !important;
  }

  .diamond-img img {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .form-input {
    width: 80%;
  }

  .crtPassword {
    margin-left: 7px;
  }

  .cmn-input-cls .textField input {
    width: 400px !important;
  }

  .cmn-input-cls .textField {
    width: auto;
  }

  .right-content::after {
    right: -35px;
  }

  .choice {
    margin: 10px auto 10px;
  }

  .login-page1 {
    background-image: url(../../../img/Userloginnewbg.png);
    padding: 20px 14px !important;
  }
}

.login-main-text {
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 400;
  margin-top: -95px;
  margin-bottom: 100px;
}

.login-main-text1 {
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 400;
  margin-top: -75px;
  margin-bottom: 40px;
}

.user-login-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-text {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.watch-img {
  margin: 25px 0px;
  width: 100px;
  /* height: 100px; */
}

.crtPassword1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recover-img {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
}

.recover-text1 {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 30px 0px;
}

.recover-text2 {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px 0px;
}

.submit1 {
  padding: 5px 10px;
  border-radius: 6.2px;
  border: 3.1px solid #0065ff !important;
  background: linear-gradient(134deg, #fff -26.79%, #d1d1d1 230.75%) !important;
  box-shadow: 0px 3.1px 0px 0px #fff inset,
    3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16) !important;
  color: #151515 !important;
  font-size: 14px !important;
  display: flex;
  margin: auto;
  text-transform: capitalize !important;
  margin-top: 15px;
}

.we-product p {
  max-width: 250px;
  margin-left: 90px;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}

.we-product {
  position: absolute;
  top: 50px;
  left: 170px;
  width: auto;
}

.register-form-innerdiv {
  overflow-x: hidden !important;
  max-height: 238px !important;
  padding: 20px 0px !important;
}

.location-search-input {
  width: 100% !important;
}