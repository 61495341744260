.head-topic {
  font-size: 13px !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}

.row-topic {
  font-size: 12px !important;
  font-weight: 400 !important;
  text-align: left !important;
}

.modelTypo {
  color: #000;
  text-align: center;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.billing-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0px 15px;
}

div#earnings-sales table tbody tr td {
  text-align: center !important;
  padding: 8px !important;
  word-break: break-all;
  /* white-space: nowrap; */
  /* max-width: 1200px; */
  /* white-space: wrap !important; */
}

@media (max-width: 991.98px) {
  .billing-btn-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: 0px 15px;
  }
}

.quantity-bg {
  border-radius: 15px;
  background: rgba(23, 85, 231, 0.3) !important;
}

.total-pdts {
  padding: 30px 0 30px 30px;
  border-radius: 29.635px;
  background: #1755e7 !important;
}
/* 
.total-pdts.quantity-bg p,
.total-pdts.quantity-bg .total-pdts-div-left span,
.total-pdts.quantity-bg .total-pdts-div-left label {
    color: #000 !important;
} */

.years-part {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.years-part p {
  font-size: 15.464px;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

.pagination-secyion {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.five-block-divs-two-rows-top-inner-five-blk-inner-content.span-bg span {
  color: #219653;
  padding: 2px;
  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  border-radius: 61.233px;
  background: rgba(33, 150, 83, 0.2);
}

button.filter-btn {
  border-radius: 5.313px;
  border: 2.656px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
  color: #fff;
}

@media all and (max-width: 991.98px) {
  /*Edited by Sankar */
  .popBOX.BlockPopup.filterpopup {
    backdrop-filter: none !important;
    width: 540px !important;
  }
}

.popBOX.BlockPopup.cancel-invoice {
  backdrop-filter: none !important;
}

p.new-invoice {
  font-size: 20px;
  font-weight: 700;
}

.invoice-create {
  text-align: center;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.field-txt-box {
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.max-size {
  width: 300px;
}

button.create-green-btn {
  border-radius: 4.519px;
  border: 2.259px solid #00ff29;
  background: linear-gradient(134deg, #0f6 -26.79%, #8b8b8b 230.75%);
  color: #fff;
  padding: 5px 22px;
}

button.cancel-green-btn {
  border-radius: 4.519px;
  border: 2.259px solid #f00;
  background: linear-gradient(134deg, #f00 -26.79%, #8b8b8b 230.75%);
  color: #fff;
  padding: 5px 22px;
}

.create-cancel-btn {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: baseline;
  margin-top: 10px;
}

.start-end-date.start-end-date-admin.invoice-date .MuiStack-root {
  justify-content: flex-start !important;
}

.popBOX.BlockPopup.cancel-invoice {
  width: 800px !important;
  height: 56%;
}

.check-box-align {
  text-align: left;
  max-width: 448px !important;
  margin: 0 auto;
}

.create-cancel-btn.invoice-reject {
  margin-top: 51px;
  gap: 50px !important;
}

.file-upload {
  background: #fff !important;
}

.icon-space button {
  padding: 0px !important;
  min-width: 40px;
}

.upload-box {
  color: #000 !important;
  display: flex !important;
  padding: 80px !important;
  font-size: 20px !important;
  border: 3px dotted gray !important;
}

p.paid-txt {
  font-size: 20px;
}

/* .accnt-btn {
    display: flex;
    align-items: start;
    padding: 0 80px;
    gap: 25px;
    margin-bottom: 30px;
} */

.qr-detail {
  margin-top: 0 !important;
}

@media (max-width: 991.98px) {
  .brand-billing-filter-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div#five-block-divs-two-rows-top {
    display: block;
  }

  .invoice-reject {
    display: block;
    text-align: center;
  }

  .create-btn {
    margin-bottom: 5px;
  }

  .pagination-secyion,
  .years-part {
    display: block;
  }

  .five-block-divs-two-rows-top-inner {
    width: 79%;
    margin: 0 auto 10px;
  }

  div#five-block-divs-two-rows {
    margin: auto;
    width: 90% !important;
  }

  div#speciality-3 {
    padding: 0;
  }

  /* .accnt-btn {
        display: block;
        padding: 0;
    } */

  button.filter-btn {
    margin-bottom: 10px;
    margin-top: 10px !important;
  }
  .dashboargraph-inner-top {
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .popBOX.BlockPopup.cancel-invoice {
    width: 286px !important;
    height: 74%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .popBOX.BlockPopup.cancel-invoice {
    width: 500px !important;
    height: 74%;
  }
}

@media (max-width: 767.98px) {
  .date-display {
    flex-direction: column;
    gap: 10px;
  }
  .popBOX.BlockPopup.filterpopup {
    width: 216px !important;
    height: 74%;
  }
  .seeAccountingGeneral {
    flex-direction: column !important;
    align-items: flex-start !important;
}

.popBOX .seeAccountingGeneral .start-end-date .MuiInputBase-root {
    width: 200px!important;
}
.seeAccountingGeneral .start-end-date.start-end-date-admin.invoice-date .MuiStack-root{
margin-bottom: 0 !important;
}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .popBOX.BlockPopup.cancel-invoice {
    width: 520px !important;
    height: 74%;
  }
  .start-end-date.start-end-date-admin.invoice-date .MuiStack-root {
    justify-content: center !important;
  }
}

.qr-detail.cancel-invoice {
  margin-top: 0;
}

.popBOX.BlockPopup.cancel-invoice {
  height: auto !important;
}

.billing-top-btn {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.billing-top-btn.brand-bill {
  padding: 0 30px;
  align-items: start;
}

.date-display {
  display: flex;
  gap: 20px;
}

@media (max-width: 1499px) {
  .billing-top-btn {
    flex-direction: column;
  }

  .billing-top-btn {
    gap: 10px;
  }

  .billing-top-btn.brand-bill {
    align-items: center !important;
  }
  /* 
    .accnt-btn {
        width: -webkit-fill-available;
        padding: 0 80px;
        justify-content: start !important;
    } */
}

.seeAccountingGeneral {
  display: flex;
  justify-content: space-around;
}
.reset-filter button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #0065FF;
    color: #fff;
    padding: 8px;
    border: none;
    border-radius: 8px;
    text-align: center;
    width: max-content;
}
.seeAccountingGeneral {
    display: flex;
    align-items: center;
    justify-content: space-around;
}