div#profile-block {
  padding: 50px 25px;
  border-radius: 16.667px 16.667px 0 0 !important;
  border: 0.986px solid rgba(203, 239, 255, 0.16) !important;
  background: rgba(255, 255, 255, 0.76) !important;
  backdrop-filter: blur(98.61111450195312px) !important;
}

/* .profile-inner {
    background: #fff !important;
} */
.gotoDiv {
  display: flex;
  margin-bottom: 20px;
}

.profilebrand {
  border-radius: 11px 0 0 11px;
  background: rgba(167, 198, 234, 0.2);
  margin-bottom: 100px;
  text-align: right;
  padding: 4px 30px 4px 10px;
}

.input-textfield-block {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
}

.input-textfield-block label {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  width: 150px;
  text-align: left;
}

.input-textfield-block input {
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  height: 54px;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
}

.cmn-input-cls.input-textfield-block .MuiInputBase-root {
  height: 54px;
}

.input-textfield-block fieldset {
  border-radius: 6.458px;
  border: 1.615px solid #fff;
  opacity: 0.6;
}

.input-textfield-block .MuiInputBase-root {
  width: 400px;
}

.MuiInputBase-root:hover fieldset,
.MuiInputBase-root:hover input {
  border: none;
}

a.Change-password {
  background: rgba(240, 158, 0, 1);
  color: #fff;
  text-decoration: none;
  padding: 7px 15px;
  display: inline-block;
}

.Change-password-outer {
  margin-top: 10px;
  text-align: right;
}

.input-textfield-block.Update-Data button {
  text-transform: none;
  font-size: 18px;
}

.input-textfield-block.Update-Data {
  justify-content: center;
  margin-top: 40px;
}

.input-textfield-block.Change-password-outer {
  justify-content: flex-end;
  padding-right: 190px;
}

.block-item h5 {
  color: #151515;
  font-size: 20px;
  margin: 5px 0px 10px;
}

.text-block-div {
  font-size: 20px;
  padding: 15px 20px;
  background: #fff;
  display: inline-block;
  color: #000;
}

.three-block-profile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  border: 0.887px solid rgba(203, 239, 255, 0.16);
  background: rgba(174, 192, 236, 0.8);
  backdrop-filter: blur(39.04999923706055px);
}

.text-block-div.approved {
  background: transparent;
  padding: 0px;
}

.text-block-div.approved img {
  width: 50px;
}

.block-item {
  padding-top: 35px;
  padding-bottom: 40px;
}

.company-details {
  text-align: left;
}

.company-details label {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.company-details p {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.profile-image-outer {
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: 0px;
}

.profile-image-outer h4 {
  font-size: 18px;
  margin: 15px 0px 5px;
  color: #000;
}

.profile-image-outer img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.profile-image-outer {
  margin: 0px 10px 10px 0;
  text-align: center;
}

.edit-image-btn {
  position: absolute;
  bottom: 6px;
  width: 95%;
  padding: 0;
  background: transparent;
  border-radius: 10px;
  left: 5px;
  text-align: center;
  height: 200px;
}

/* .edit-image-btn:hover {
    position: absolute;
    bottom: 6px;
    width: 95%;
    padding: 0;
    border-radius: 0;
    left: 5px;
    text-align: center;
    height: 200px;
    background: #373636ad;
} */
.preview-img .edit1 svg {
  font-size: 30px;
}

.preview-img:hover .edit1 {
  align-items: center;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.preview-img .edit1 {
  background: #373636ad;
  color: #fff;
  display: none;
  height: 192px;
  left: 5px;
  line-height: 4;
  padding: 4px;
  position: absolute;
  text-align: center;
  bottom: 6px;
  width: 192px;
  cursor: pointer;
}

.edit-image-btn button {
  text-transform: none;
  display: none;
}

.change-psswrd-block-popup .input-textfield-block {
  padding-left: 0px;
}

.change-psswrd-block-popup .input-textfield-block {
  padding-left: 0px;
  margin-bottom: 10px;
}

.input-textfield-block.passwrd-change-btn {
  justify-content: center;
}

.input-textfield-block.passwrd-change-btn button {
  text-transform: none;
}

.close-change-passwrd-pop-up {
  text-align: right;
  margin-bottom: 5px;
}

.close-change-passwrd-pop-up {
  cursor: pointer;
}

.upload-image-block {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.profile-image.profile-image-design-block {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  padding: 5px;
  padding-bottom: 0;
  background: #fff;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
}

.upload-image-block svg {
  margin-top: -55px;
  font-size: 50px;
}

.message-popup .listOne {
  display: block;
  gap: 35px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.popBOX.message-popup .listOne .SellerPaidTable table th {
  text-align: center;
  color: #000;
  border: 0.757px solid rgba(203, 239, 255, 0.16);
  background: rgba(174, 192, 236, 0.8);
}

.popBOX.message-popup .headTitle {
  color: #1e1b39;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.popBOX.message-popup td.MuiTableCell-root.MuiTableCell-body {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.76);
  backdrop-filter: blur(98.61111450195312px);
}

/* .popBOX.message-popup {
    height: auto !important;
} */

.firm-logo img {
  height: 60px;
  width: 120px;
  background: #fff;
  border-radius: 5px;
  padding: 4px;
}

@media (max-width: 991.98px) {
  .input-textfield-block {
    flex-direction: column;
    padding-left: 0;
  }

  .input-textfield-block div,
  .input-textfield-block label {
    width: 100% !important;
  }

  .three-block-profile {
    flex-direction: column;
  }

  div#profile-block {
    padding: 20px;
  }

  .input-textfield-block.Change-password-outer {
    padding-right: 0;
  }

  .profilebrand {
    margin: 50px 0 30px 0;
  }

  .change-psswrd-block-popup {
    width: 80%;
    margin: 0;
  }

  .cmn-input-cls.input-textfield-block.profile-pass-box
    .MuiInputBase-root.MuiOutlinedInput-root {
    width: auto !important;
  }
}
